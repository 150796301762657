import React from "react";
import Link from "next/link";
import Image from "next/image";
import { styled } from "stitches.config";
import { BaseLayout } from "src/common/components/layouts/BaseLayout";
import Image404 from "src/assets/svg/404.svg";

const ImageStyled = styled(Image, {
  padding: 0,
  lineHeight: 1,
});

export default function page404() {
  return (
    <BaseLayout>
      <div className="container px-lg-0">
        <div className="row d-flex mb-6 pt-5 mt-1">
          <Link href="/rent" passHref>
            <a className="p-0">
              <ImageStyled src="/svg/gleevery.svg" alt="Logo Gleevery" width={108} height={26} />
            </a>
          </Link>
        </div>
      </div>

      <div className="mt-lg-0 mt-5">
        <div className="d-flex flex-column align-items-center py-8">
          <Image404 className="w-lg-auto w-100" />
          <p className="fs-xl text-master-dark fw-bold text-center mb-2">Ojej coś poszło nie tak!</p>
          <p className="text-master-dark fs-xxs mt-0 ">Error code: 404</p>
          <Link href="/rent" passhref>
            <a className="btn btn-primary py-3 fs-xs">Idź do strony głównej</a>
          </Link>
        </div>
      </div>
    </BaseLayout>
  );
}
